<template>
  <div class="complaint">
    <van-nav-bar
      title="投诉与建议"
      :right-text="pid == 0 ? '历史提交' : ''"
      left-arrow
      @click-right="onClickRight"
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-form @submit="onSubmit">
        <van-field
          v-show="pid > 0 ? false : true"
          v-model="category"
          name="category"
          label="分类"
          @click="showAction"
          readonly
          is-link
        >
          <template #input>
            {{category}}
          </template>
        </van-field>
        <van-field
          class="textarea_class"
          v-model="content"
          type="textarea"
          name="content"
          :border="false"
          placeholder="详细描述您的问题"
          :style="'min-height:' + (screenWidth - 100) + 'px;'"
          :rules="[{ required: true, message: '请描述您的问题' }]"
        />
        <van-field :border="false" style="padding-bottom: 0;" label="补充图片" readonly></van-field>
        <van-field name="uploader">
          <template #input>
            <van-uploader
              v-model="uploader"
              :after-read="afterRead"
              :before-read="beforeRead"
              :before-delete="beforeDelete"  />
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnloding" :native-type="btnloding ? 'button' : 'submit'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" @select="onSelect" />
  </div>
</template>
<script>
export default {
  name: "Complaint",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      content: "", // 反馈内容
      category: "客服投诉&表扬", // 分类
      uploader: [], // 上传文件
      pic: [],
      categoryId: 1,
      actions: [
        { name: '客服投诉&表扬', value: 1 },
        { name: '订单/商品投诉咨询', value: 2 },
        { name: '投诉会员', value: 3 },
        { name: '建议与意见', value: 4 },
      ],
      show: false,
      btnloding: false,
      pid: 0,
      info: {}
    }
  },
  mounted() {
    this.pid = this.$route.query.id ? this.$route.query.id : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "投诉与建议");
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      if (this.pid > 0) {
        let _that = null;
        _that = this;
        _that.$axios
          .post(
            _that.$store.state.domain + "web/feedback/get_one",
            _that.$qs.stringify({
              id: _that.pid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.info = res.data.data;
              _that.categoryId = _that.info.type;
              _that.category = _that.info.title;
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 提交按钮
    onSubmit() {
      console.log("submit");
      let _that = null;
      _that = this;

      _that.btnloding = true; // 防止发生多次误点

      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/feedback/add_suggestion",
          _that.$qs.stringify({
            categoryId: _that.categoryId,
            category: _that.category,
            content: _that.content,
            pic: _that.pic,
            pid: _that.pid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "提交成功");
            setTimeout(() => {
              _that.btnloding = false;
              _that.onClickLeft();
            }, 1000);
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "提交失败");
            _that.btnloding = false;
          }
        })
        .catch(err => {
          console.log(err);
          _that.btnloding = false;
        })
    },

    showAction(){
      this.show = true;
    },
    onSelect(item){
      console.log(item);
      this.category = item.name;
      this.categoryId = item.value;
      this.show = false;
    },
    // 文件上传
    // 上传前判断只有返回true 才能上传
    beforeRead() {
      return true;
    },
    // 上传
    afterRead(file, detail) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/tousu/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        if (response.data && response.data.code == 100000) {
          _that.pic.push(response.data.img_url);
          console.log(_that.pic);
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "上传失败");
        }
      }).catch( error =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        console.log(error);
      })
    },
    beforeDelete(file, detail) {
      this.uploader.splice(detail.index, 1);
      this.pic.splice(detail.index, 1);
    },
    onClickRight() {
      if (this.pid == 0) {
        this.$router.push({ name: "ComplaintList", query: {shareid: this.shareid, merchid: this.merchid } });
      }
    }
  },
}
</script>
<style lang="less">
.complaint {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  .van-nav-bar .van-icon,
  .van-nav-bar__text {
    color: #333;
  }

  .content {
    padding-bottom: 60px;
    .van-form {
      .van-field__label {
        width: 65px;
        text-align: left;
      }
      .van-field__value {
        flex:1;
        .van-field__body {
          height: 90%;
          .van-field__control {
            min-height: 100%;
          }
        }
      }
      .textarea_class {
        .van-field__value {
          border: 1px solid #eee;
          padding: 5px;
        }
      }
    } 
  }
}
</style>